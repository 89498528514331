import App from "@/pages/App";
import BlankIt from "./pages/BlankIt";
import Shuffler from "./pages/Shuffler";
import Archieve from "./pages/Archieve";

const routes = [
  {
    path: "",
    element: <App />,
    // errorElement: <ErrorPage />,
  },
  {
    path: "/blankit",
    element: <BlankIt />,
  },
  {
    path: "/shuffler",
    element: <Shuffler />,
  },

  {
    path: "/archieve",
    element: <Archieve />,
  },
];
export default routes;
