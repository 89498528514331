import { callSaveContentApi } from "@/api/callApi";
import { GA_ACTION, GA_CATEGORY } from "@/googleAnalytics/constants";
import { gaEvent } from "@/googleAnalytics/event";
import { useBlankit } from "@/hooks/useBlankit";
import { Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";

const ModalInputText = ({
  modalOpen,
  setModalOpen,
  text,
}: {
  modalOpen: boolean;
  setModalOpen: (arg: boolean) => void;
  text: string;
}) => {
  const { setTargetText } = useBlankit();
  const [inputText, setInputText] = useState(text || "");

  const onClickOk = async () => {
    if (!inputText) return;
    // 기존 텍스트와 다른 경우에만 업데이트
    if (text.localeCompare(inputText) !== 0) {
      gaEvent({
        category: GA_CATEGORY.click,
        action: GA_ACTION.changeBlankitTargetText,
      });
      setTargetText(inputText);
      await callSaveContentApi(inputText)
    }

    setModalOpen(false);
  };

  return (
    <Modal
      title="지문을 입력해주세요."
      centered
      open={modalOpen}
      onOk={() => onClickOk()}
      onCancel={() => setModalOpen(false)}
      width={1000}
      bodyStyle={{ height: 500 }}
    >
      <TextArea
        rows={20}
        value={inputText}
        onChange={(e) => {
          setInputText(e.currentTarget.value);
        }}
      />
    </Modal>
  );
};

export default ModalInputText;
