import { Card, Space } from 'antd';

const CardItem = ({ title, contents, maxWidth , linkToMore}: { title:string, contents: any, maxWidth?:number, linkToMore?:string }) => (
    <Card title={title} extra={linkToMore ? <a href={linkToMore}>More</a> : undefined} style={{ maxWidth: maxWidth || 300 }}>
        {contents}
      {/* <p>Card content</p>
      <p>Card content</p>
      <p>Card content</p> */}
    </Card>
);

export default CardItem;