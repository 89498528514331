import { ITableData } from "./interface";

const sampleFileData = [
  ["accounting", "경리, 회계 (업무), 회계(학)"],
  ["candidate", "후보자, 후보생, 지원자"],
  ["deserve", "받을 자격[가치]이 있다, ~할 만하다"],
  ["delay", "지연,  지체, 연기 ; 지연시키다"],
  ["absence", "결근, 결석, 부재"],
  ["surface", "표면, 외관"],
];

export const sampleTableData: Array<ITableData> = sampleFileData.map(
  (word, index) => {
    return {
      key: `${index}`,
      quiz: word[0] as string,
      answer: word[1] as string,
    };
  }
);

export const tableColumns = [
  {
    title: "문제",
    dataIndex: "quiz",
    key: "quiz",
  },
  {
    title: "답",
    dataIndex: "answer",
    key: "answer",
  },
];
