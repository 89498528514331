import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./route";
import ReactGA from "react-ga4";

ReactGA.initialize([
  {
    trackingId: "G-F4E1QCF7Z2",
    // gaOptions: {...}, // optional
    // gtagOptions: {...}, // optional
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const router = createBrowserRouter(routes);
root.render(
  // <React.StrictMode> // Dev환경에서 컴포넌트 2번씩 랜더링 되는 이슈 방지를 위해 React.StrictMode 주석
  <RouterProvider router={router} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
