import MainLayout from "@/layout/MainLayout";
import { useState } from "react";
import "./App.css";
import { Table, Button } from "antd";
import { MainTitle } from "@/library/antd/Title";
import ShufflerDescriptions from "@/components/02_Shuffler/00_Descriptions";
import {
  sampleTableData,
  tableColumns,
} from "@/components/02_Shuffler/sampleData";
import { LoadWordsButton } from "@/components/02_Shuffler/LoadWordsButton";
import { ReactExcelDownload } from "@/components/02_Shuffler/ExportToExcelButton";
import { ITableData } from "@/components/02_Shuffler/interface";

function Shuffler() {
  const [tableDataSource, setTableDataSource] =
    useState<Array<ITableData>>(sampleTableData);

  const shuffle = () => {
    /** 순서 셔플 */
    let shuffled = [...tableDataSource];
    shuffled = shuffled.sort(() => Math.random() - 0.5);
    for (const rowNum in shuffled) {
      if (Math.random() - 0.5 > 0) {
        const row = shuffled[rowNum];
        const temp = row["quiz"];
        row["quiz"] = row["answer"];
        row["answer"] = temp;
        shuffled[rowNum] = row;
      }
    }
    console.log(shuffled);

    setTableDataSource(shuffled);
  };

  return (
    <MainLayout header>
      <MainTitle>단어 시험지 순서 섞기</MainTitle>
      <p>단어시험지의 문제 순서를 랜덤으로 섞을 때 활용하는 서비스입니다. </p>
      <ShufflerDescriptions />
      <div>
        <LoadWordsButton setDataSource={setTableDataSource} />
        <Button
          type="primary"
          onClick={shuffle}
          // style={{ marginRight: "30px" }}
        >
          셔플
        </Button>
        <ReactExcelDownload
          apiData={tableDataSource}
          buttonTitle="엑셀로 다운로드"
          filename="단어장"
        />
      </div>

      <Table
        dataSource={tableDataSource}
        columns={tableColumns}
        pagination={false}
      />
    </MainLayout>
  );
}

export default Shuffler;
