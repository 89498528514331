import * as antd from "antd";
import type { MenuProps } from "antd";
import { MenuClickEventHandler } from "rc-menu/lib/interface";
import { useNavigate } from "react-router-dom";

export interface IMenuItem {
  label: string;
  key: string;
  path: string;
}

const Menu = ({ items }: { items: Array<IMenuItem> }) => {
  const navigate = useNavigate();
  const onMenuClick: MenuClickEventHandler = (event) => {
    const targetMenu = items.filter(
      (menuItem) => menuItem!.key == event.key
    )[0];
    if (!targetMenu) {
      console.error("메뉴 경로를 찾을 수 없습니다.");
    } else {
        if (/^https?:\/\//.test(targetMenu.path)) {
          // If the path is an external link, open it in a new tab
          window.open(targetMenu.path, "_blank");
        } else {
          // For internal paths, use the navigate function
          navigate(targetMenu.path);
        }
      }
      // navigate(targetMenu.path);
    // }
  };
  return (
    <antd.Menu
      theme="dark"
      mode="horizontal"
      items={items as MenuProps["items"]}
      onClick={onMenuClick}
    />
  );
};

export default Menu;
