import { Collapse } from "antd";
import CollapsePanel from "antd/es/collapse/CollapsePanel";

const BlankitDescriptions = () => {
  return (
    <Collapse>
      <CollapsePanel header="이용 가이드" key="1">
        <p>
          1. <strong>지문 불러오기</strong> 버튼 클릭하여 원하는 지문을
          붙여넣기합니다.
        </p>
        <p>2. 왼쪽 지문에서 빈칸으로 만들고 싶은 단어들을 클릭합니다.</p>
        <p>
          3. 오른쪽 <strong>테스트지/정답</strong>에서 빈칸 뚫린 지문을 복사하여
          활용합니다.
        </p>
      </CollapsePanel>
    </Collapse>
  );
};

export default BlankitDescriptions;
