import MainLayout from "@/layout/MainLayout";
import { MainTitle } from "@/library/antd/Title";
import { Col, Row } from "antd";
import { blankitPlaceholder } from "@/contants";
import Editor from "@/components/01_Blankit/01_Editor";
import ResultTabs from "@/components/01_Blankit/02_ResultTabs";
import { BlankitContextProvider } from "@/hooks/useBlankit";
import BlankitDescriptions from "@/components/01_Blankit/00_Descriptions";

function BlankIt() {
  return (
    <MainLayout header>
      <MainTitle>지문 빈칸 뚫기</MainTitle>
      <p>
        지문에서 원하는 단어를 편하게 빈칸으로 바꿔주는 기능입니다. 빈칸으로
        바꾸고 싶은 단어를 클릭해보세요. 원하는 지문을 불러와서 활용할 수도
        있습니다.{" "}
      </p>
      <p style={{ display: "none" }}>
        빈칸문제 만들기, 빈칸 뚫기, 영어 지문 빈칸 만들기, 영어지문 빈칸 편하게,
        빈칸뚫기 프로그램, 빈칸뚫기 사이트, 빈칸 만들기 사이트, 빈칸 만들기
        프로그램
      </p>
      <BlankitDescriptions />
      <BlankitContextProvider initialText={blankitPlaceholder}>
        <div>
          <Row gutter={32}>
            <Col span={12}>
              <Editor />
            </Col>
            <Col span={12}>
              <ResultTabs />
            </Col>
          </Row>
        </div>
      </BlankitContextProvider>
    </MainLayout>
  );
}

export default BlankIt;
