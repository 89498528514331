import { Layout, theme } from "antd";
// import BreadcrumbLayout from "./BreadcrumbLayout";
import MainMenuLayout from "./Menu";
// import SideMenuLayout from "./SideMenuLayout";

const { Header, Content, Footer } = Layout;
// const { Header, Content, Footer, Sider } = Layout;

interface IMainLayoutParams {
  header: any;
  children: any;
}

const MainLayout = ({ header, children }: IMainLayoutParams) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout>
      <Header className="header">
        <div className="logo" />
        <MainMenuLayout />
      </Header>
      <Content style={{ padding: "0 50px" }}>
        {/* <BreadcrumbLayout /> */}
        <Layout style={{ padding: "24px 0", background: colorBgContainer }}>
          {/* <SideMenuLayout /> */}
          <Content
            style={{
              padding: "0 24px",
              minHeight: 800,
              maxWidth: 2000,
              margin: "0 auto",
              width: "100%",
            }}
          >
            {children}
          </Content>
        </Layout>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        E-StudyTools : 영어 자료 제작소 - 영어 빈칸 뚫기, 단어장 섞기, 영어 문제
        자동 제작
      </Footer>
    </Layout>
  );
};

export default MainLayout;
