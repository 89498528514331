import MainLayout from "@/layout/MainLayout";
import CardItem from "@/library/antd/Card";
import "@/pages/App.css";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom"; // Import Link for routing

function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/my-path", title: "Balnkit" });
  }, []);

  return (
    <MainLayout header>
      <div className="App">
        <header>
          <h1 style={{ fontSize: '3rem', fontWeight: 'bold' }}>Welcome to E-studyTools</h1>
          <div style={{ maxWidth: '1000px', margin: '0 auto' }}>
            <p style={{ fontSize: '1.5rem' }}> E-studytools는 영어 학습을 보다 효율적으로 할 수 있도록 돕는 쉽고 편리한 도구들을 직접 개발합니다.  </p>
            <p style={{ fontSize: '1.5rem' }}>  우리는 영어를 배우는 과정이 쉽지 않다는 것을 잘 알고 있습니다. </p>
            <p style={{ fontSize: '1.5rem' }}> 그래서 누구나 쉽게 사용할 수 있는 도구들을 제공하여 학습의 준비의 어려움을 덜어줍니다. </p>
            <p style={{ fontSize: '1.5rem' }}> E-studytools의 목표는 사용자들이 더 효과적으로 학습에 집중할 수 있도록 돕는 것입니다. </p>
          </div>
          
          <Link to="https://forms.gle/fjKKiNbAgdUkV2V17" style={{ fontSize: '1.5rem', color: '#007acc', textDecoration: 'underline' }}>
            서비스에 대한 의견 남기기
          </Link> 
        </header>

        <section className="content-section" style={{ display: 'flex', justifyContent: 'space-around', padding: '20px' }}>
          <div className="column" style={{ width: '45%' }}>
            <CardItem maxWidth={800} title={"Master English Through Structured Repetition"} contents={
              <p style={{ fontSize: '1.25rem' }}>
                One of the most effective methods for mastering the English language is through the repetitive reading of well-constructed texts. Repetition is key in reinforcing language patterns, vocabulary, and sentence structures. When learners frequently encounter sentences built with correct grammar and coherent ideas, their minds begin to internalize these structures naturally. Over time, this consistent exposure leads to improved comprehension and fluency.
              </p>
            } />
          </div>

          <div className="column" style={{ width: '45%' }}>
          <CardItem maxWidth={800} title={"Why Repetition and Active Engagement Work"} contents={
              <p style={{ fontSize: '1.25rem' }}>
              The repetitive nature of reading and rewriting grammatical structures encourages active learning, which is a significant factor in long-term retention. The brain tends to encode information more effectively when the learner is actively engaged in the learning process. This means that passive recognition of grammar and vocabulary is less effective than direct application and recall.
            </p>
          }/>
          </div>
        </section>

      </div>
    </MainLayout>
  );
}

export default App;
