import { isNewLineChar, useBlankit } from "@/hooks/useBlankit";
import { useEffect, useState } from "react";

export const TabAnswer = () => {
  const { blankitWordList } = useBlankit();
  const [blankitParagraph, setBlankitParagraph] = useState<any>();

  useEffect(() => {
    const _convertToParagraph = () => {
      return blankitWordList.map((word) => {
        if (word.mode === "SHOW") return word.text + " ";
        else if (word.mode === "HIDE") {
          return (
            <>
              <span style={{ textDecoration: "underline" }}>{word.text}</span>
              <span> </span>
            </>
          );
        } else if (isNewLineChar(word)) {
          return <br />;
        } else return <></>;
      });
    };
    setBlankitParagraph(_convertToParagraph());
  }, [blankitWordList]);
  return (
    <div style={{ padding: "10px 16px", display: "flex", flexWrap: "wrap" }}>
      <p style={{ lineHeight: "26px", letterSpacing: "0.4px" }}>
        {blankitParagraph}
      </p>
    </div>
  );
};
