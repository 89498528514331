import { Button, Collapse } from "antd";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import { ReactExcelDownload } from "./ExportToExcelButton";
import { sampleTableData } from "./sampleData";

const ShufflerDescriptions = () => {
  const DownloadSampleFileButton = () => {
    return (
      <ReactExcelDownload
        apiData={sampleTableData}
        buttonTitle="샘플 파일 다운로드"
        filename="단어장 샘플 "
      />
    );
  };
  return (
    <Collapse>
      <CollapsePanel header="이용 가이드" key="1">
        <p>
          1. 샘플 파일 형식에 맞줘 단어시험지를 준비합니다.{" "}
          <DownloadSampleFileButton />
        </p>
        <p>
          2. <strong>단어 파일 업로드</strong> 버튼을 눌러 준비한 파일을
          업로드합니다.
        </p>
        <p>
          3. <strong>셔플</strong> 버튼을 누르면 단어의 한/영과 순서가 섞입니다.
        </p>
        <p>
          4. <strong>다운로드</strong> 버튼을 통해 섞인 단어장 다운로드하여
          활용합니다.
        </p>
      </CollapsePanel>
    </Collapse>
  );
};

export default ShufflerDescriptions;
