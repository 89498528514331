import { Button } from "antd";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { ITableData } from "./interface";
export const ReactExcelDownload = ({
  apiData,
  buttonTitle,
  filename,
}: {
  apiData: any;
  buttonTitle: string;
  filename: string;
}) => {
  const excelFileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const excelFileExtension = ".xlsx";
  const _tableDataToExcelJson = (data: Array<ITableData>) => {
    return data.map((value) => ({ col1: value.quiz, col2: value.answer }));
  };

  const excelDownload = (apiData: any) => {
    const xlsxData = _tableDataToExcelJson(apiData);
    const ws = XLSX.utils.json_to_sheet(xlsxData);
    const wb: any = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelButter = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, filename + excelFileExtension);
  };

  return (
    <Button
      onClick={() => {
        excelDownload(apiData);
      }}
    >
      {buttonTitle}
    </Button>
  );
};
