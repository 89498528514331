import { stripPunctuations } from "@/hooks/useBlankit";
import { IBlankitWord } from "@/interface/blankit";
import { memo, MouseEventHandler } from "react";

const EditorWordItem = ({
  wordItem,
  onClick,
}: {
  wordItem: IBlankitWord;
  onClick: MouseEventHandler<HTMLDivElement>;
}) => {
  return (
    <div onClick={onClick} style={{ margin: "4px 1px" }}>
      {wordItem.mode === "SHOW" && <WordItemShow text={wordItem.text} />}
      {wordItem.mode === "HIDE" && <WordItemHide text={wordItem.text} />}
      {wordItem.mode === "ETC" && <div />}
    </div>
  );
};

export default memo(EditorWordItem);

const WordItemShow = memo(({ text }: { text: string }) => {
  return (
    <div
      style={{
        margin: "4px 1px",
        // borderRadius: "10px",
        padding: "1px 4px 3px 4px",
        display: "inline",
      }}
    >
      <span>{text}</span>
    </div>
  );
});

const WordItemHide = memo(({ text }: { text: string }) => {
  const { prePunc, postPunc, word } = stripPunctuations(text);
  return (
    <div
      style={{
        margin: "4px 2px",
        // borderRadius: "10px",
        padding: "1px 4px 3px 4px",
        display: "inline",
        background: "#efefef",
      }}
    >
      <span>{prePunc}</span>
      <span
        style={{
          // borderBottom: "1px solid #333333",
          color: "grey",
          // color: "#d9d9d9",
        }}
      >
        {word}
      </span>
      <span>{postPunc}</span>
    </div>
  );
});
