import {
  isNewLineChar,
  stripPunctuations,
  useBlankit,
} from "@/hooks/useBlankit";
import { IBlankitWord, IBlankitWordList } from "@/interface/blankit";
import { Button, Typography } from "antd";
import { Fragment, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
const { Text } = Typography;

export const TabBlanked = () => {
  const { blankitWordList } = useBlankit();
  const [blankitParagraph, setBlankitParagraph] = useState<string>("");

  useEffect(() => {
    let text = "";
    for (const item of blankitWordList) {
      text += _getBlankedWord(item);
    }
    setBlankitParagraph(text);
  }, [blankitWordList]);

  const BlankedTextArea = () => {
    const textList = blankitParagraph.split("\n");
    return (
      <p style={{ lineHeight: "26px", letterSpacing: "0.4px" }}>
        {textList.map((text, index) => (
          <Fragment key={index}>
            {text}
            <br />
          </Fragment>
        ))}
      </p>
    );
  };

  const CopyToClipboardButton = () => {
    return (
      <CopyToClipboard
        text={blankitParagraph}
        onCopy={() => alert("클립보드에 복사되었습니다.")}
      >
        <Button type="primary">클립보드에 복사하기</Button>
      </CopyToClipboard>
    );
  };

  const _getBlankedWord = (item: IBlankitWord) => {
    if (item.mode === "SHOW") return `${item.text} `;
    if (item.mode === "HIDE") {
      const { prePunc, postPunc, word } = stripPunctuations(item.text);
      return `${prePunc}${"_".repeat(word.length)}${postPunc} `;
    }
    if (isNewLineChar(item)) {
      return "\n";
    }
  };

  return (
    <div style={{ padding: "10px 16px", display: "flex", flexWrap: "wrap" }}>
      <BlankedTextArea />
      <div style={{ textAlign: "right", width: "100%" }}>
        <CopyToClipboardButton />
      </div>
    </div>
  );
};
