export const callSaveContentApi = async (userContent:string) => {
    try {
        const response = await fetch('https://dev-api.dodory.kr/est/user-content', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userContent })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log("API call succeeded:", data); // Handle the successful response
        return data;
    } catch (error) {
        console.error("API call failed:", error); // Handle any error
        return null; // Return null or some fallback value in case of failure
    }
};