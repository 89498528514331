import Menu, { IMenuItem } from "../library/antd/Menu";

const menus: Array<IMenuItem> = [
  {
    label: "지문 빈칸 뚫기",
    key: "blankit",
    path: "/blankit",
  },
  {
    label: "단어 시험지 순서 섞기",
    key: "shuffler",
    path: "/shuffler",
  },
  {
    label: "영어듣기를 더 편하게",
    key: "dodory",
    path: "https://dodory.kr/",
  },
  {
    label: "의견 남기기",
    key: "survey",
    path: "https://forms.gle/fjKKiNbAgdUkV2V17",
  },
  // {
  //   label: "자료실",
  //   key: "archieve",
  //   path: "/archieve",
  // },
];

const MainMenuLayout = () => {
  return <Menu items={menus} />;
};

export default MainMenuLayout;
