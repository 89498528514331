export const GA_CATEGORY = {
  click: "click",
};

export const GA_ACTION = {
  click: "click",
  openChangeBlankitTargetTextModal: "openChangeBlankitTargetTextModal",
  changeBlankitTargetText: "changeBlankitTargetText",
  toggleBlankitWord: "toggleBlankitWord",
};

// category: "your category",
// action: "click",
// label: "your label", // optional
// value: 99, // optional, must be a number
// nonInteraction: false, // optional, true/false
