import { isNewLineChar, useBlankit } from "@/hooks/useBlankit";
import { IBlankitWord } from "@/interface/blankit";
import EditorWordItem from "./EditorWordItem";
import { gaEvent } from "@/googleAnalytics/event";
import { GA_ACTION, GA_CATEGORY } from "@/googleAnalytics/constants";

export const EditorParagraph = ({
  wordItem,
  index,
}: {
  wordItem: IBlankitWord;
  index: number;
}) => {
  const { blankitWordList, setBlankitWordList } = useBlankit();
  const onClickWordItem = (wordItem: IBlankitWord, wordItemIndex: number) => {
    gaEvent({
      category: GA_CATEGORY.click,
      action: GA_ACTION.toggleBlankitWord,
    });
    const newBlankitWordList = [...blankitWordList];
    newBlankitWordList[wordItemIndex] = _toggleBlankitWordMode(wordItem);
    setBlankitWordList(newBlankitWordList);
  };

  if (isNewLineChar(wordItem)) {
    return <NewLine index={index} />;
  } else
    return (
      <EditorWordItem
        key={`wordItem-${index}`}
        wordItem={wordItem}
        onClick={() => onClickWordItem(wordItem, index)}
      />
    );
};

const _toggleBlankitWordMode = (item: IBlankitWord) => {
  const newItem = { ...item };
  newItem.mode =
    item.mode === "SHOW" ? "HIDE" : item.mode === "HIDE" ? "SHOW" : item.mode;
  return newItem;
};

const NewLine = ({ index }: { index: number }) => (
  <div key={`newline${index}`} style={{ width: "100%" }} />
);
