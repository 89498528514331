import { Tabs } from "antd";
import { TabAnswer } from "./TabAnswer";
import { TabBlanked } from "./TabBlanked";

const ResultTabs = () => {
  const items = [
    {
      label: "테스트지",
      key: "tab-blanked",
      children: <TabBlanked />,
    },
    {
      label: "정답",
      key: "tab-answer",
      children: <TabAnswer />,
    },
  ];
  return <Tabs items={items} />;
};

export default ResultTabs;
