import { useBlankit } from "@/hooks/useBlankit";
import { Button } from "antd";
import { Dispatch, SetStateAction, useState } from "react";
import BorderedBox from "../../BorderedBox";
import { EditorParagraph } from "./EditorParagraph";
import ModalInputText from "@/components/01_Blankit/ModalInputText";
import { gaEvent } from "@/googleAnalytics/event";
import { GA_ACTION, GA_CATEGORY } from "@/googleAnalytics/constants";

const BlankitEditorComponentEmpty = ({
  setModalOpen,
}: {
  setModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const _onClickOpenLoadTextModal = () => {
    gaEvent({
      category: GA_CATEGORY.click,
      action: GA_ACTION.openChangeBlankitTargetTextModal,
    });
    setModalOpen(true);
  };

  return (
    <BorderedBox>
      <div style={{ textAlign: "center" }}>
        <Button onClick={_onClickOpenLoadTextModal}>텍스트 불러오기</Button>
      </div>
    </BorderedBox>
  );
};

const BlankitEditorComponent = ({
  setModalOpen,
}: {
  setModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { blankitWordList, setTargetText } = useBlankit();
  const ResetTextButton = () => {
    const _onClickOpenLoadTextModal = () => {
      gaEvent({
        category: GA_CATEGORY.click,
        action: GA_ACTION.openChangeBlankitTargetTextModal,
      });
      setModalOpen(true);
    };
    return (
      <div style={{ textAlign: "right" }}>
        <Button type="text" danger onClick={_onClickOpenLoadTextModal}>
          지문 불러오기
        </Button>
      </div>
    );
  };
  return (
    <>
      <ResetTextButton />
      <BorderedBox>
        <div style={{ float: "left", display: "flex", flexWrap: "wrap" }}>
          {blankitWordList.map((wordItem, index) => (
            <EditorParagraph
              key={`BlankitWordItem-${index}`}
              wordItem={wordItem}
              index={index}
            />
          ))}
        </div>
      </BorderedBox>
    </>
  );
};

const Editor = () => {
  const { blankitWordList, targetText } = useBlankit();
  const [modalOpen, setModalOpen] = useState(false);

  const isParagraphEmpty = () => {
    return blankitWordList.length === 0;
  };

  return (
    <div style={{ marginTop: "13px" }}>
      {isParagraphEmpty() && (
        <BlankitEditorComponentEmpty setModalOpen={setModalOpen} />
      )}
      {!isParagraphEmpty() && (
        <BlankitEditorComponent setModalOpen={setModalOpen} />
      )}
      <ModalInputText
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        text={targetText}
      />
    </div>
  );
};

export default Editor;
