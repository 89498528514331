import { Button } from "antd";
import { useRef } from "react";
import readXlsxFile, { Row } from "read-excel-file";

export const LoadWordsButton = ({
  setDataSource,
}: {
  setDataSource: Function;
}) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const _constructTableDataSource = (rows: Array<Row>) => {
    const dataSource = [];
    for (const rowNum in rows) {
      if (rowNum !== "0") {
        // 제목 행은 패스
        dataSource.push({
          key: rowNum,
          quiz: rows[rowNum][0] as string,
          answer: rows[rowNum][1] as string,
        });
      }
    }
    setDataSource(dataSource);
  };

  const _onUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      console.log(e.target.files[0]);
      const targetFile = e.target.files[0];
      try {
        readXlsxFile(targetFile).then((rows) => {
          console.log(rows);
          _constructTableDataSource(rows);
        });
      } catch (err) {
        alert(err);
      }
    }
  };

  return (
    <>
      <Button
        onClick={() => {
          if (fileInput.current) {
            fileInput.current.click();
          }
        }}
      >
        단어 파일 업로드
      </Button>
      <input
        type="file"
        ref={fileInput}
        style={{ display: "none" }}
        onChange={_onUploadFile}
      />
    </>
  );
};
