import ReactGA from "react-ga4";

export const gaEvent = ({
  category,
  label,
  action,
  value,
}: {
  category: string;
  action: string;
  label?: string;
  value?: number;
}) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label, // optional
    value: value, // optional, must be a number
    nonInteraction: false, // optional, true/false
    // transport: "xhr", // optional, beacon/xhr/image
  });
};
