import MainLayout from "@/layout/MainLayout";
import { useState } from "react";
import "./App.css";
import { Table, Button, Card } from "antd";
import { MainTitle } from "@/library/antd/Title";
import Meta from "antd/es/card/Meta";
import { LinkOutlined } from "@ant-design/icons";

function Archieve() {
  return (
    <MainLayout header>
      <MainTitle>자료실</MainTitle>
      <p>영어 학습, 자료 제작 관련 자료실입니다. </p>
      <Card
        style={{ width: 300 }}
        cover={
          <img
            alt="example"
            src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
          />
        }
        onClick={() =>
          window.open(
            "https://docs.google.com/spreadsheets/d/1txjCLHq8wOfkW2Asq1JOGLH_yvw-zyJ7dZLwnUvY-Jg/edit?usp=sharing",
            "_blank"
          )
        }
        actions={[<LinkOutlined key="link" />]}
      >
        <Meta
          title="자동 검색 단어장"
          description="영어 단어를 입력하면 오른쪽 셀에 뜻을 자동으로 채워주는 구글 스프레드시트 양식"
        />
      </Card>
    </MainLayout>
  );
}

export default Archieve;
