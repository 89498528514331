import { IBlankitWord, IBlankitWordList } from "@/interface/blankit";
import { createContext, useContext, useEffect, useState } from "react";

const BlankitContext = createContext({});

interface IBlankitContext {
  setTargetText: React.Dispatch<React.SetStateAction<string>>;
  setBlankitWordList: React.Dispatch<React.SetStateAction<IBlankitWordList>>;
  blankitWordList: IBlankitWordList;
  targetText: string;
}

export const BlankitContextProvider = ({ children, initialText }: any) => {
  const [targetText, setTargetText] = useState(initialText);
  const [blankitWordList, setBlankitWordList] = useState<IBlankitWordList>([]);

  useEffect(() => {
    const newBlankitWordList = _textToBlankitWordList(targetText);
    setBlankitWordList(newBlankitWordList);
  }, [targetText]);

  const contextValue = {
    setTargetText,
    setBlankitWordList,
    blankitWordList,
    targetText,
  };

  return (
    <BlankitContext.Provider value={contextValue}>
      {children}
    </BlankitContext.Provider>
  );
};

export const useBlankit = () => {
  const state = useContext(BlankitContext) as IBlankitContext;
  if (!state) throw new Error("Cannot find BlankitContextProvider"); // 유효하지 않을땐 에러를 발생
  return state;
};

/** --------- helpers from here ------ */
const _textToBlankitWordList = (text: string) => {
  const paragraphList = text.split("\n");
  let result: IBlankitWordList = [];
  for (const i in paragraphList) {
    const paragraph = paragraphList[i];
    const wordList = paragraph.split(" ").filter((item) => item !== ""); // 공백제거
    const blankitWordList = wordList.map(
      (word) =>
        ({
          text: word,
          mode: "SHOW",
        } as IBlankitWord)
    );

    result = result.concat(blankitWordList);
    if (Number(i) < paragraphList.length - 1) {
      result = result.concat([
        {
          text: "\n",
          mode: "ETC",
        },
      ]);
    }
  }
  return result;
};

const _isAlphabet = (char: string) => char.toUpperCase() !== char.toLowerCase();

/** 개행문자인 경우 true 반환  */
export const isNewLineChar = (item: IBlankitWord) =>
  item.mode === "ETC" && item.text === "\n";

/** 단어 앞뒤의 문장부호 분리 */
export const stripPunctuations = (str: string) => {
  let prePunc = "";
  let word = "";
  let postPunc = "";

  let i;
  for (i = 0; i < str.length; i++) {
    const char = str.charAt(i);
    if (_isAlphabet(char)) {
      break;
    }

    prePunc = prePunc + char;
  }

  let j;
  for (j = str.length - 1; j > i; j--) {
    const char = str.charAt(j);
    if (_isAlphabet(char)) {
      break;
    }

    postPunc = char + postPunc;
  }

  word = str.substring(i, j + 1);

  return {
    prePunc,
    postPunc,
    word,
  };
};
