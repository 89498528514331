const BorderedBox = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      style={{
        border: "1px solid #d9d9d9",
        minHeight: "20px",
        borderRadius: "6px",
        padding: "20px",
        display: "flex",
      }}
    >
      {children}
    </div>
  );
};

export default BorderedBox;
